<template>
  <div>
    <div class="imgWrapper" v-show="modalImg" @click="modalImg = undefined">
      <p class="imgWrapperText">loading image</p>
      <b-img class="imgWrapperImage" fluid-grow :src="`./imgs/${modalImg}`" alt="" />
      <div class="imgWrapperIcon">
        <b-icon class="imgWrapperIcon" icon="x" />
      </div>
    </div>
    <div class="hero">
      <h1>my pc config</h1>
      <p>Scroll down to see a list of the hardware I use in my PC setup.</p>
      <p>
        <router-link id="back" title="◄ go back" to="/">◄ back</router-link>
      </p>
    </div>
    
    <div class="project" v-for="p in pcconfig" :key="p.title">
      <h1 :id="p.type">{{p.type}}<a :href="`#${p.type}`" id="hyperlink"><b-icon icon="link45deg" class="icon"/></a></h1>
      <span>{{p.model}}</span><br>
      <span>~{{p.price}}€</span><br>
      <span>links: 
        <span v-if="affiliate"><span v-for="l in p.links.affiliate" :key="l.url">[<a id="hyperlink" :href="l.url" target="_blank">{{l.title}}</a>]</span></span>
        <span v-if="!affiliate"><span v-for="l in p.links.nonaffiliate" :key="l.url">[<a :href="l.url" id="hyperlink" target="_blank">{{l.title}}</a>]</span></span>
      </span><br><br>
      <span>{{p.description}}</span><br>
    </div>

    <div class="project" v-if="affiliate">
      <span>Be aware that as an Amazon partner, I receive a small commission for purchases made through these links. This does not change the price for you.</span>
    </div>

  </div>
</template>

<script>
  import pcconfig from '@/assets/texts/pcconfig';
  export default {
    name: 'Projects',
    data() {
      return {
        modalImg: '',
        pcconfig,
        affiliate: true,
      };
    },
    methods: {
      showImg(img) {
        this.modalImg = img;
        // this.$bvModal.show('imgModal')
      },
    },
    mounted() {
      this.affiliate = this.$route.query.noaffiliate !== undefined ? false : true;
    }
  }
</script>

<style scoped>

p {
  color: #3b3b3b;
  font-size: 300%;
  margin: 0;
}

  .hero {
    height: 100vh;
  }

  .project {
    margin-bottom: 40rem;
  }

  .imgWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(10, 10, 10, 0.5);
    padding: 5rem;
  }

  .imgWrapperText {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .imgWrapperImage {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 20;
    padding: 15rem;
  }

  .imgWrapperIcon {
    z-index: 100;
    height: 2rem;
    width: 2rem;
    color: black;
    background-color: white;
    border-radius: 0.2rem;
    position: fixed;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  .imgWrapperIcon:hover {
    color: white;
    background-color: black;
  }

  .icon {
    margin-left: 1rem;
    font-size: 16pt;
  }
</style>